.header {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 88px;
  font-weight: 700;
  font-size: 14px;
  background-color: var(--page-bg);
  margin-bottom: 100px;
  @media (max-width: 1440px) {
    height: 75px;
  }
  @media (max-width: 1080px) {
    margin-bottom: 40px;
  }
  @media (max-width: 430px) {
    height: 55px;
  }

  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 0 0 30px;
    width: 240px;
    height: 88px;
    background-color: #fff;
    @media (max-width: 1440px) {
      width: 198px;
      height: 75px;
    }
    @media (max-width: 430px) {
      width: 131px;
      height: 55px;
    }
    &-img {
      width: 123px;
      height: 23px;
      @media (max-width: 430px) {
        width: 78px;
        height: 13px;
      }
    }
  }

  &__menu {
    display: flex;
    flex-grow: 1;
    align-items: center;
    @media (max-width: 1080px) {
      display: none;
    }
  }

  &__region {
    display: flex;
    align-items: center;
    margin-left: 55px;
    @media (max-width: 1080px) {
      color: var(--black);
      margin-left: 0;
      margin-bottom: 20px;
    }
  }

  &__icon {
    position: relative;
    top: 1px;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 50%;
    margin-left: 5.5px;
    width: 20px;
    height: 20px;
    background-color: #fff;
    &-img {
      width: 14px;
      height: 8px;
      stroke: #000;
    }
  }

  &__nav {
    margin-left: auto;
    @media (max-width: 1080px) {
      margin: 0 0 20px;
    }
  }

  &__list {
    position: relative;
    display: flex;
    align-items: center;
    gap: 42px;
    @media (max-width: 1080px) {
      flex-direction: column;
      gap: 20px;
    }
  }

  &__list-dropdown {
    display: none;
    position: absolute;
    z-index: 1;
    top: 30px;
    left: -40px;
    border-radius: 12px;
    width: 135px;
    height: 76px;
    padding: 16px 20px;
    background-color: #fff;
    &-link {
      font-weight: 700;
      font-size: 14px;
      color: #2d61e3;
      transition: opacity 0.3s ease-in-out;
      cursor: pointer;
      margin-bottom: 10px;
      &:hover {
        opacity: 0.6;
      }
    }
  }

  &__link {
    color: var(--light);
    transition: color 0.3s ease-in-out;
    &:hover {
      color: var(--text-clor);
      @media (max-width: 1080px) {
        color: var(--page-bg);
      }
    }
  }

  &__link.active {
    color: var(--text-clor);
  }

  &__button-login {
    margin-right: 120px;
    margin-left: 88px;
    display: flex;
    align-items: center;
    overflow: hidden;
    border-radius: 30px;
    padding: 5px 13px 7px 15px;
    font-weight: 700;
    font-size: 14px;
    line-height: 1.2;
    background-color: var(--blue);
    transition: color 0.35s ease, background-color 0.35s ease;
    @media (max-width: 1080px) {
      margin: 0;
    }

    &:hover {
      color: #2d61e3;
      background-color: #fff;
      & .button-login__img {
        background-color: #2d61e3;
      }
    }
  }
}

.button-login {
  display: flex;
  align-items: center;

  &__img {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    margin-left: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--rose);
  }

  &__icon {
    width: 10px;
    height: 10px;
  }
}
