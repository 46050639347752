@font-face {
	font-family: Gilroy;
	font-display: swap;
	src: url("../fonts/Gilroy-Black.woff2") format("woff2"), url("../fonts/Gilroy-Black.woff") format("woff");
	font-weight: 900;
	font-style: normal;
}
@font-face {
	font-family: Gilroy;
	font-display: swap;
	src: url("../fonts/Gilroy-Medium.woff2") format("woff2"), url("../fonts/Gilroy-Medium.woff") format("woff");
	font-weight: 500;
	font-style: normal;
}
@font-face {
	font-family: Gilroy;
	font-display: swap;
	src: url("../fonts/Gilroy-SemiBold.woff2") format("woff2"), url("../fonts/Gilroy-SemiBold.woff") format("woff");
	font-weight: 600;
	font-style: normal;
}
@font-face {
	font-family: Roboto;
	font-display: swap;
	src: url("../fonts/Roboto-Bold.woff2") format("woff2"), url("../fonts/Roboto-Bold.woff") format("woff");
	font-weight: 700;
	font-style: normal;
}
@font-face {
	font-family: Roboto;
	font-display: swap;
	src: url("../fonts/Roboto-Regular.woff2") format("woff2"), url("../fonts/Roboto-Regular.woff") format("woff");
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: Gilroy;
	font-display: swap;
	src: url("../fonts/Gilroy-Bold.woff2") format("woff2"), url("../fonts/Gilroy-Bold.woff") format("woff");
	font-weight: 700;
	font-style: normal;
}
