/* Base */
@import './base/reset';
@import './base/vars';
@import './base/mixins';
@import './base/fontsAutoGen';
@import './base/basic';
@import './base/utils';
@import './base/containers';
@import './base/sticky-footer';

/* Blocks */
@import "blocks/_actions.scss";
@import "blocks/_chempions.scss";
@import "blocks/_footer.scss";
@import "blocks/_header.scss";
@import "blocks/_mobile-nav.scss";
@import "blocks/_partaking.scss";
@import "blocks/_prizes.scss";
@import "blocks/_titles.scss";
