html {
  scroll-behavior: smooth;
  background-color: rgb(39, 39, 39);
}

body {
  background-color: var(--page-bg);
  color: var(--text-color);
  font-family: var(--font-main);
  text-wrap: balance;
}

img {
  display: block;
  width: 100%;
  object-fit: cover;
}
