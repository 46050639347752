.partaking {
  border-radius: 30px;
  width: 100%;
  padding: 56px 0 0 40px;
  margin-bottom: 100px;
  background-color: #396ef3;
  background-image: url('../img/bg-image.svg');
  background-position: right bottom;
  background-repeat: no-repeat;

  @media (max-width: 1080px) {
    margin-bottom: 75px;
  }

  &__items {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__title {
    font-weight: 700;
    font-size: 42px;
    max-width: 350px;
    margin-bottom: 25px;
    @media (max-width: 430px) {
      font-size: 28px;
    }
  }

  &__list {
    margin-bottom: 60px;
    @media (max-width: 430px) {
      margin-bottom: 33px;
    }

    &-item {
      font-weight: 600;
      font-size: 28px;
      line-height: 150%;
      margin-left: 30px;
      @media (max-width: 430px) {
        font-size: 16px;
        margin-left: 20px;
      }
    }
  }

  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    width: 264px;
    height: 53px;
    font-weight: 700;
    font-size: 22px;
    text-align: center;
    background-color: var(--accent);
    color: var(--text-color);
  }

  &__image {
    max-width: 685px;
  }
}
