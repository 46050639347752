.chempions {
  margin-bottom: 100px;

  @media (max-width: 1080px) {
    margin-bottom: 75px;
  }

  &__title {
    margin-bottom: 40px;

    @media (max-width: 1080px) {
      margin-bottom: 32px;
    }
  }

  &__list {
    width: 100%;
    height: 570px;
    padding: 48px 40px;
    background-color: var(--text-color);
    color: var(--page-bg);
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    @media (max-width: 768px) {
      padding: 30px 15px;
    }
  }

  &__list-header {
    width: 97.5%;
    min-height: 60px;

    border-bottom: 1px dashed var(--page-bg);
    font-family: var(--font-accent);
    font-weight: 700;
    font-size: 22px;
    display: flex;
    align-items: center;

    @media (max-width: 1080px) {
      display: none;
    }
  }

  &__list-header-cell {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 22px;

    &:nth-child(1) {
      width: 100%;
      max-width: 60px;
    }
    &:nth-child(2) {
      width: 100%;
      max-width: 278px;
    }
    &:nth-child(3) {
      width: 100%;
      max-width: 514px;
    }
    &:nth-child(4) {
      width: 100%;
      max-width: 348px;
    }
  }

  &__items {
    padding-right: 30px;
    overflow-y: scroll;
  }

  &__item {
    width: 100%;
    min-height: 60px;
    border-bottom: 1px dashed var(--page-bg);
    font-family: var(--font-accent);
    font-weight: 400;
    font-size: 18px;
    color: #545454;
    display: flex;
    align-items: center;

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      align-items: start;
      border-bottom: 1px stroke #545454;
      padding: 16px 0;
    }
  }

  &__item-cell {
    display: flex;
    align-items: center;
    @media (max-width: 1080px) {
      font-size: 18px;
    }

    &:nth-child(1) {
      width: 100%;
      max-width: 60px;
      font-weight: 700;
      @media (max-width: 1080px) {
        display: none;
      }
    }
    &:nth-child(2) {
      width: 100%;
      max-width: 278px;
      font-weight: 700;
      @media (max-width: 1080px) {
        max-width: 200px;
      }
      @media (max-width: 768px) {
        order: 2;
        margin-bottom: 8px;
        color: var(--page-bg);
      }
    }
    &:nth-child(3) {
      width: 100%;
      max-width: 514px;
      @media (max-width: 768px) {
        order: 1;
        font-size: 14px;
        margin-bottom: 16px;
      }
    }
    &:nth-child(4) {
      width: 100%;
      max-width: 348px;
      @media (max-width: 768px) {
        order: 3;
      }
    }
  }
}
