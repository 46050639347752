:root {
  --container-width: 1332px;
  --container-padding: 15px;

  --font-main: 'Gilroy', sans-serif;
  --font-accent: 'Roboto', sans-serif;
  --font-titles: var(--font-main);

  --page-bg: #2d61e3;
  --text-color: #fff;
  --accent: #f43d4f;
  --rose: #ff88c4;
  --first-color: #477cff;
  --second-color: #96b4ff;
  --black: #000;
  --blue: #356fff;
  --deep-blue: #5977cf;
  --light-blue: #5e8cff;
  --light: #779eff;

  --laptop-size: 1440px;
  --tablet-size: 1230px;
  --mobile-size: 430px;
}
